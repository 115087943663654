.block-video {
	a {

	}
}

.video--withPlayButton {
	display: block;
	position: relative;

	&:before {
		@extend %icon-font;
		@include scaling(75, 150, 'width');
		@include scaling(75, 150, 'height');
		@include scaling($marginDefaultPx*.25, $marginDefaultPx*.75, 'padding-left');
		@include scaling-fs(35, 70);
		position: absolute;
		top: 50%;
		left: 50%;
		background-color: $c-flamingo;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		content: $icon-play;
		transform: translate(-50%,-50%);
		color: #fff;
		z-index: 10;
	}
}

.video--decorative {
	position: relative;

	//screen to prevent mouse clicks
	&.videoImages-mask:after {
		position: absolute;
		content: '';
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 2;
	}
}