.block-caseStudyInfo {

}

.caseStudyInfo-main {
	.text-calloutHeading {
		color: $c-silverChalice;
	}
}

.caseStudyInfo-sub {
	@include query_min($bp-desktop) {
		padding-right: $paddingDefault*2;
	}
}