@charset "UTF-8";

// Default Variables
$slick-loader-path: "./" !default;
$slick-dot-color: #000 !default;
$slick-dot-color-active: #000 !default;
$slick-font-family: sans-serif !default;
$slick-dot-character: '\2022' !default;
$slick-dot-character-active: '\2022' !default;
$slick-dot-size: 8px !default;
$slick-opacity-default: 1 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;
$slick-arrow-left: '<' !default;
$slick-arrow-right: '>' !default;
$slick-arrow-height: 35px !default;
$slick-arrow-width: 18px !default;
$slick-arrow-color: #fff !default;
$slick-arrow-color-hover: lighten(#fff, 25%) !default;
$slick-arrow-offset: 0 !default;

@function slick-image-url($url) {
	@return url($slick-loader-path + $url);
}

/* Slider */
.slick-list {
	.slick-loading & {
		background: #fff url("/assets/svg/ajax-loader-white.svg") center center no-repeat;
	}
}

/* Arrows */
.slick-prev,
.slick-next {
	position: absolute;
	display: none;
	height: $slick-arrow-height;
	width: $slick-arrow-width;
	line-height: 0;
	font-size: 0;
	cursor: pointer;
	background: transparent;
	color: transparent;
	top: 50%;
	margin-top: $slick-arrow-height * -.5;
	padding: 0;
	border: none;
	outline: none;
	z-index: 1000;
	text-align: center;

	&:hover, &:focus {
		outline: none;
		background: transparent;
		color: transparent;
		&:before {
			opacity: $slick-opacity-on-hover;
			transition: opacity $transitionTime linear, color $transitionTime linear;
			color: $slick-arrow-color-hover;
		}
	}
	&.slick-disabled:before {
		opacity: $slick-opacity-not-active;
	}

	&:before {
		font-family: $slick-font-family;
		@include fontsize($slick-arrow-height);
		line-height: 1;
		color: $slick-arrow-color;
		opacity: $slick-opacity-default;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		width: 100%;
		text-align: center;
		display: block;
		position: absolute;
		z-index: 2;
		top: 0;
	}
}

.slick-prev {
	left: $slick-arrow-offset;
	[dir="rtl"] & {
		left: auto;
		right: $slick-arrow-offset;
	}
	&:before {
		content: $slick-arrow-left;
		[dir="rtl"] & {
			content: $slick-arrow-right;
		}
	}
}

.slick-next {
	right: $slick-arrow-offset;
	[dir="rtl"] & {
		left: $slick-arrow-offset;
		right: auto;
	}
	&:before {
		content: $slick-arrow-right;
		[dir="rtl"] & {
			content: $slick-arrow-left;
		}
	}
}

/* Dots */
.slick-dots {
	position: absolute;
	list-style: none;
	display: block;
	text-align: center;
	padding: 0;
	width: 100%;
	li {
		position: relative;
		display: inline-block;
		margin: 0 $marginDefault;
		padding: 0;
		cursor: pointer;
		button {
			border: 0;
			background: transparent;
			display: inline-block;
			outline: none;
			line-height: 0;
			font-size: 0;
			color: transparent;
			cursor: pointer;
			&:hover, &:focus {
				outline: none;
			}
			&:before {
				height: $slick-dot-size;
				content: $slick-dot-character;
				font-family: $slick-font-family;
				font-size: $slick-dot-size;
				text-align: center;
				color: $slick-dot-color;
				transition: color $transitionTime linear;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
			}
		}
		&.slick-active button:before {
			color: $slick-dot-color-active;
			content: $slick-dot-character-active;
		}
	}

	li + li {
		margin-top: 0 !important;
	}
}