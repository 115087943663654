$fs-menuSub: 15px !default;

.navApp {
	position: relative;
	font-family: $ff-tertiary;
	display: flex;
	flex: 0 1 auto;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	padding: $paddingDefault*2 0;
	min-height: min-content;
	margin: auto;
	@include scaling-fs(50,86, $bp-desktop-px);
	line-height: 1.1;
	width: 100%;

	@include query_max($bp-desktop-px - 1px) {
		overflow-x: hidden;
	}

	//wide, short screens on the homepage need a little top padding
	//so the text won't break out of the container
	.homepage & {
		@include query_min($bp-desktop) {
			@include query_max_height($bp-desktop) {
				padding: $paddingDefault*7 0 $paddingDefault;
			}
		}
	}
}

.navApp-inner {
	display: flex;
	flex-direction: column;
	position: relative;
	width: 100%;
}

.navApp-menuWrapper {
	width: 100%;
	overflow: hidden;
	display: block;
}

.navApp-menu {
	margin: 0 auto;
	text-align: center;
	align-items: center;
	position: relative;
	z-index: 2;
	opacity: 1;
	width: 100%;
	display: block;

	@include query_max($bp-desktop-px - 1px) {
		opacity: 1 !important;
	}

	li {
		margin-top: 0;
		list-style-type: none;

		&.pastDivider {
			margin-top: $marginDefault*2;

			+ .pastDivider {
				margin-top: $marginDefault*1.5;
			}
		}
	}

	a {
		text-decoration: none;
		color: $c-flamingo;
		text-transform: lowercase;
		display: inline-block;
		@extend .diamondParent--onHover !optional;

		@include query_min($bp-desktop) {
			position: relative;
		}

		span {
			@extend .diamondLineBoth !optional;
			display: inline-block;
			padding: 0 $marginDefault*2;
		}
	}

	.navApp-altTitle {
		text-transform: uppercase;
		color: $c-doveGray;
		font-family: $ff-secondary;
		@include fontsize($fs-menuSub);
		display: block;
		margin-top: -3px;
		letter-spacing: 1px;

		&:before, &:after {
			content: none;
		}
	}

	.navApp-altTitleInner {
		&:before, &:after {
			content: none;
		}
	}

	.pastDivider {
		font-family: $ff-secondary;
		@include fontsize($fs-menuSub);

		a {
			text-transform: uppercase;
			letter-spacing: 1px;

			span {
				@extend .diamondBoth !optional;
			}
		}
	}
}

.navApp-introText {
	position: absolute;
	width: 100%;
	top: 0;
	left: 0;
	color: $c-gray2;
	display: none;
	z-index: 1;
	opacity: 0;
	pointer-events: none;

	@include query_max($bp-desktop-px - 1) {
		display: none !important;
	}
	@include query_min($bp-desktop-px) {
		display: block;
	}

	a {
		color: $c-gray2;
		text-decoration: none;
		display: inline-block;
		transition: color 1s;
	}
}

.navApp-introTextInner {
	text-align: left;
	display: inline-block;
}

.navApp-introText-line {
	position: relative;
	opacity: 0;
	top: 0;
	transition: opacity 1s, top 1s;
}

//transition stages
@include query_min($bp-desktop) {

	.navApp-menu {
		.navApp-altTitle {
			//display: none !important;
			margin-top: -7px;
			position: absolute;
			max-height: 0;
			overflow: hidden;
			transition: max-height 0.5s;
		}
		.navApp-altTitleInner {
			opacity: 0;
			transition: opacity 1s;
		}
	}

	.pastDivider {
		opacity: 0;
	}

	//if the homepage, show the intro text
	.homepage {
		.navApp-introText {
			opacity: 1;
		}
		.navApp-menu {
			@include opacity(0);

			//remove pointer events
			a {
				pointer-events: none;
			}

			span {
				&:before, &:after {
					display: none;
				}
			}
		}
	}

	//ready to transition text
	.navApp-pre0 {
		.homepage & {
			.navApp-introText-line {
				opacity: 0;
				top: 50px;
			}
		}
	}

	//transition text
	.navApp-pre1 {
		.homepage & {
			.navApp-introText-line {
				opacity: 1;
				top: 0;
			}
		}
	}

	//fade in links
	.navApp-stage0 {
		.navApp-introText-line {
			transition: none;
			opacity: 1;
			top: 0;
		}

		.navApp-introText {
			a {
				color: $c-flamingo;
			}
		}

		.navApp-menu {
			transition: opacity 1s;

			//restore pointer events
			a {
				pointer-events: auto;
			}
		}
	}

	//show menu links and fade out text
	.navApp-stage1 {
		.navApp-introText-line {
			transition: opacity 1s;
			opacity: 0 !important;
		}

		.navApp-menu {
			opacity: 1;

			a {
				transition: left 1s, opacity 1s;
			}
		}
	}

	//slide items into place
	.navApp-stage2 {
		.navApp-menu {
			opacity: 1;

			a {
				left: 0 !important;
			}
		}

		.pastDivider {
			transition: opacity 1s;
		}
	}

	//show sub links
	.navApp-stage3 {
		.pastDivider {
			opacity: 1;
		}

		.navApp-menu {
			span {
				&:before, &:after {
					display: block;
				}
			}

			//increase positioning for the alt titles
			.navApp-altTitle {
				position: relative;
				max-height: 32px;
			}

			.navApp-altTitleInner {
				opacity: 1;
			}
		}
	}
}
