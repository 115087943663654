.map {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #ddd4cf;

	h1 {
		@include fontsize(16px);
		margin: 0;
		line-height: normal;
	}

	&-holder {
		position: relative;
		padding-bottom: 50%;
	}
}