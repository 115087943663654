//pagination link styles

$fs-pageLink: 14px !default;

.pageLink {
	text-decoration: none;
	text-transform: uppercase;
	font-weight: bold;
	@include fontsize($fs-pageLink);
	font-family: $ff-secondary;
	letter-spacing: .1em;
	color: #000;

	&.disabled {
		cursor: default;
		color: $c-silverSand;
	}

	span {
		display: inline-block;
		@include fontsize($fs-default);
		position: relative;
		top: 1px;
	}
}

.pageLink-prev {
	span {
		margin-right: $marginDefault;
	}
}

.pageLink-next {
	span {
		margin-left: $marginDefault;
	}
}