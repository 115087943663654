.bgBorders,
.bgRule:before {
	width: 33.33vw;
	min-width: 300px;
	max-width: 590px;
	z-index: -1;
	left: 50%;
	transform: translateX(-50%);
	position: absolute;
	top: 0;
}

.bgBorders {
	position: fixed;
	bottom: 0;
	border-left: 1px solid $c-silver;
	border-right: 1px solid $c-silver;
}

.bgRule {
	display: block;
	margin: 0 auto;
	position: relative;
	z-index: -2;

	&:before {
		content: '';
		border-top: 1px solid $c-silver;
	}
}