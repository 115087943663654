/*@font-face {
	font-family: 'Majesti Banner';
	src: url('/assets/fonts/majesti-banner-light-webfont.woff2') format('woff2'),
	url('/assets/fonts/majesti-banner-light-webfont.woff') format('woff');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'Majesti Banner';
	src: url('/assets/fonts/majesti-banner-lightitalic-webfont.woff2') format('woff2'),
	url('/assets/fonts/majesti-banner-lightitalic-webfont.woff') format('woff');
	font-weight: 200;
	font-style: italic;
}

@font-face {
	font-family: 'Majesti Banner';
	src: url('/assets/fonts/majesti-banner-book-webfont.woff2') format('woff2'),
	url('/assets/fonts/majesti-banner-book-webfont.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Majesti Banner';
	src: url('/assets/fonts/majesti-banner-bookitalic-webfont.woff2') format('woff2'),
	url('/assets/fonts/majesti-banner-bookitalic-webfont.woff') format('woff');
	font-weight: 300;
	font-style: italic;
}*/

@font-face {
	font-family: 'Majesti Banner';
	src: url('/assets/fonts/majesti-banner-medium-webfont.woff2') format('woff2'),
	url('/assets/fonts/majesti-banner-medium-webfont.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

/*@font-face {
	font-family: 'Majesti Banner';
	src: url('/assets/fonts/majesti-banner-mediumitalic-webfont.woff2') format('woff2'),
	url('/assets/fonts/majesti-banner-mediumitalic-webfont.woff') format('woff');
	font-weight: 400;
	font-style: italic;
}*/

@font-face {
	font-family: 'Majesti Banner';
	src: url('/assets/fonts/majesti-banner-bold-webfont.woff2') format('woff2'),
	url('/assets/fonts/majesti-banner-bold-webfont.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}

/*@font-face {
	font-family: 'Majesti Banner';
	src: url('/assets/fonts/majesti-banner-bolditalic-webfont.woff2') format('woff2'),
	url('/assets/fonts/majesti-banner-bolditalic-webfont.woff') format('woff');
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: 'Majesti Banner';
	src: url('/assets/fonts/majesti-banner-heavy-webfont.woff2') format('woff2'),
	url('/assets/fonts/majesti-banner-heavy-webfont.woff') format('woff');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Majesti Banner';
	src: url('/assets/fonts/majesti-banner-heavyitalic-webfont.woff2') format('woff2'),
	url('/assets/fonts/majesti-banner-heavyitalic-webfont.woff') format('woff');
	font-weight: 800;
	font-style: italic;
}*/
