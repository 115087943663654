.block {
	overflow-y: visible;
	overflow-x: hidden;
}

.block-padded--sm {
	padding-top: $paddingDefault;
	padding-bottom: $paddingDefault;
}

.block-padded--md {
	padding-top: $paddingDefault*2;
	padding-bottom: $paddingDefault*2;
}

.block-padded--lg {
	padding-top: $paddingDefault*3;
	padding-bottom: $paddingDefault*3;
}

.block-padded--xl {
	padding-top: $paddingDefault*4;
	padding-bottom: $paddingDefault*4;
}

.block-padded--xxl {
	padding-top: $paddingDefault*5;
	padding-bottom: $paddingDefault*5;
}

.block-padded--top-none {
	padding-top: 0;
}

.block-padded--bottom-none {
	padding-bottom: 0;
}