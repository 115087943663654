//---------- Colors ----------
$c-concrete: #f2f2f2;
$c-alto: #cecece; //bg color
$c-doveGray: #6e6e6e; //bg color, text color for alto bg
$c-iron: #d8d9da; //hero heading color
$c-mineShaft: #343434; //text
$c-mineShaft2: #2C2C2C; //bg color
$c-silver: #c3c3c3; //bg guides
$c-silverChalice: #a2a2a2; //heading color for alto bg and dove gray bg
$c-flamingo: #ef4e30; //under-heading border
$c-gray: #7f7f7f; //footer light text color
$c-gray2: #8f8f8f; //home text color
$c-thunder: #231f20; //menu lines
$c-silverSand: #b8b9ba; //disabled footer links

//text color
$c-text: $c-mineShaft;

//background color
$c-bodyBg: #fff;

//selection background color
$c-selection: $c-flamingo;

//forms
$c-error: red;

//---------- Spacing ----------
$marginDefaultPx: 16px;
$marginDefault: rems($marginDefaultPx);
$paddingDefaultPx: 16px;
$paddingDefault: rems($paddingDefaultPx);

//---------- Foreground Colors ----------
$arr-foregroundColors: (
	white: #fff,
	black: #000
);

//---------- Background Colors ----------
$arr-backgroundsDark: (
	doveGray: $c-doveGray,
	mineShaft: $c-mineShaft2
);
$arr-backgroundsLight: (
	white: #fff,
	transparent: transparent,
	alto: $c-alto,
	concrete: $c-concrete
);

//---------- Timing ----------
$transitionTime: .5s; //update in site.js if changed
$transitionTimeFast: .25s; //update in site.js if changed

//---------- Typography ----------
$ff-default: 'Playfair Display', serif; //weights: 400, 700
$ff-secondary: 'Montserrat', sans-serif; //weights: 400, 700
$ff-tertiary: 'Majesti Banner', serif; //weights: 400, 700

//font sizes
$fs-default: 18px;
$lh-default: 1.5372;

//---------- Breakpoints ----------
$bp-sm-px: 320px;
$bp-iPhone6-px: 375px; //for Thomas the designer's phone
$bp-mid-px: 520px;
$bp-desktop-px: 768px;
$bp-md-px: 1024px;
$bp-lg-px: 1200px;
$bp-sm: rems($bp-sm-px);
$bp-iPhone6: rems($bp-iPhone6-px);
$bp-mid: rems($bp-mid-px);
$bp-desktop: rems($bp-desktop-px);
$bp-md: rems($bp-md-px);
$bp-lg: rems($bp-lg-px);

//---------- Flexbox Grid ----------
$grid-columns: 12;
$gutter-width-px: 20px;
$gutter-width: rems($gutter-width-px);
$gutter-width-half-px: $gutter-width-px * .5;
$gutter-width-half: $gutter-width * .5;
$gutter-width-double-px: $gutter-width-px * 2;
$gutter-width-double: $gutter-width * 2;
$outer-margin: $gutter-width-double * 2;
$breakpoints: (
	sm: $bp-sm,
	mid: $bp-mid,
	desktop: $bp-desktop,
	md: $bp-md,
	lg: $bp-lg
);

$flexboxgrid-max-width-px: 1230px;
$flexboxgrid-max-width: rems($flexboxgrid-max-width-px);

//---------- Font Icons ----------
$ff-icons: 'judson-design';

//---- Icomoon Variables - Do Not Remove ----
$icon-edit: "\270e";
$icon-phone: "\1f57b";
$icon-email: "\1f582";
$icon-twitter: "\74";
$icon-facebook: "\66";
$icon-pinterest: "\70";
$icon-long-arrow-right: "\27f6";
$icon-long-arrow-left: "\27f5";
$icon-circle: "\2b24";
$icon-play: "\25b6";
$icon-crossbones: "\2620";
$icon-diamond-filled: "\25c6";
$icon-diamond: "\25c7";
//---- End Icomoon Variables - Do Not Remove ----

//---------- Slider Arrows/Dots ----------
$slick-font-family: $ff-icons;
$slick-arrow-height: 75px;
$slick-arrow-width: 25px;
//$slick-arrow-left: $icon-arrow-left;
//$slick-arrow-right: $icon-arrow-right;
$slick-dot-size: 9px;
$slick-dot-color: $c-flamingo;
$slick-dot-color-active: $c-flamingo;
$slick-dot-character: $icon-diamond;
$slick-dot-character-active: $icon-diamond-filled;