.block-richText {
	@extend .text-styledLinks;
	.text-body {
		font-size: 18px;
		line-height: 27.67/18;
	}
}

.textStyle--inset {
	&.bg--dark .text-body {
		color: #fff;
	}

	.text-heading {
		@extend .text-largeHeading;
	}
}

.textStyle--insetCaption, .textStyle--caption {
	.text-heading {
		@extend .text-labelHeading;
	}

	&.bg--dark .text-body {
		color: #fff;
	}
}

.textStyle--caption {
	.text-heading {
		@extend .text-bottomBorder;
	}

	.text-body {
		font-family: $ff-secondary;
		font-size: 14px;
		line-height: 22/14;
		color: $c-doveGray;
		letter-spacing: .1px;
	}
}