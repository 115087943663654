.block-videosImagesPair,
.videoImages-inner {
	position: relative;
	overflow:hidden;
}

.videoImages-block {
	position: relative;
	padding-bottom: 56.25%; /* aspect ration for 16:9 */
	height:0;
	width: 100%;

	@include query_min($bp-desktop) {
		padding-bottom: 28.125%; /* aspect ration for 16:9 */
		width: 50%;
	}
}

@include query_min($bp-desktop) {
	.videoImages-block + .videoImages-block {
		position: absolute;
		right: 0;
		top: 0;
	}
}

.videoImages-video {
	@extend .video--decorative;

	iframe {
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100.1%; //fix rounding error
		z-index: 1;
	}
}