@keyframes lazy-loading-stripes {
	from {
		background-position: 40px 0;
	}
	to {
		background-position: 0 0;
	}
}

@keyframes lazy-load-fadein {
	0%   { opacity: .25; }
	100% { opacity: 1; }
}

/* fade image in after load */
.lazyloadWithFade {
	&.lazyload,
	&.lazyloading {
		background-image: url(/assets/svg/ajax-loader-dove-gray.svg);
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 50px 50px;

		* {
			visibility: hidden;
		}
	}

	&.lazyloaded {
		animation: lazy-load-fadein 1s;
	}
}