$bp-image-overlay: 900px !default;

.imageWithOverlay {
	position: relative;
	display: block;

	@at-root a#{&} {
		text-decoration: none;
	}
}

.imageWithOverlay-overlay {
	background-color: rgba(#fff, .85);
	@include scaling($marginDefaultPx*1.5, $marginDefaultPx*3, 'padding', $bp-sm-px, $bp-image-overlay);
	margin-top: 1rem;

	@include query_min($bp-image-overlay) {
		position: absolute;
		top: 50%;
		left: 54%;
		width: 40%;
		transform: translateY(-50%);
		margin-top: 0;
	}

	.text-heading {
		@extend .text-labelHeading;
	}

	.text-body {
		font-family: $ff-tertiary;
		font-weight: bold;
		@include scaling-fs(16px, 27px);
		line-height: 37/27;
	}
}