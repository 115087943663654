.banner {
	@extend .diamondParent !optional;
}

.banner-inner {
	position: relative;
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
	flex-wrap: nowrap;

	.wrapper {
		position: relative;
		z-index: 2;
	}

	.row {
		padding-top: $paddingDefault*3;
		padding-bottom: $paddingDefault*3;
		min-height: 54vw;
		height: 100%;
	}

	.text-content {
		max-width: 60%;
		margin-left: auto;
		margin-right: auto;
	}

	.banner-diamondHolder {
		display: inline-block;
		@extend .diamondLineBoth--black !optional;
		padding: 0 10%;
	}
}

.banner-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	@include opacity(.5);
	visibility: visible !important;
}

.banner-logoHolder {
	text-align: center;
	margin: 0 auto;
	max-width: 90%;

	.banner-diamondHolder {
		display: block;
		padding: 0 22%;
	}

	img {
		margin-top: 0;
	}

	img[src*=".svg"] {
		width: 100%;
	}
}

.banner-heading {
	@extend .text-bannerHeading;
}

.banner-subHeading {
	@extend .text-bannerSubHeading;
}