.slides {
	&.slick-dotted {
		.slick-dots {
			position: static;
			margin-top: $marginDefault*.5;
		}
	}
}

.slides--end {
	.slick-track {
		display: flex;
		align-items: flex-end;
	}
}

.slide + .slide {
	margin-top: 0;
}

.slides--full {
	position: absolute !important;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 1;

	.slick-track, .slick-list {
		height: 100% !important;
	}

	.slide {
		display: block;
		height: 100% !important;
		width: 100%;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		object-fit: cover;
	}
}