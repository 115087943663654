$fs-menuToggleSize: 52px !default;

//---------- header ----------
.header {
	position: relative;
}

.header-inner {
	position: relative;
	width: 100%;
	margin-top: $marginDefault;

	@include query_min($bp-mid) {
		margin-top: $marginDefault*2.9;
	}

	.col {
		height: $fs-menuToggleSize;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
}

//---------- logo ----------
.logoHolder {
	display: inline-block;
	max-width: calc(100% - #{$fs-menuToggleSize} - #{$gutter-width});

	@include query_min($bp-sm) {
		max-width: 50%;
		margin-left: auto;
		margin-right: auto;
	}

	@include query_min($bp-iPhone6) {
		max-width: 64%;
	}

	@include query_min($bp-mid) {
		max-width: none;
	}
}