.image--bordered {
	border: 7px solid #fff;
}

.imageStyle--fullwidth {
	margin: 0 auto;
	img {
		width: 100% !important;
	}
}

.image--scaling {
	img {
		display: block;
		margin-left: auto;
		margin-right: auto;
	}
}

.image-overlapFullWrapper {
	width: 100%;
}

.image--overlap {
	position: relative;
	overflow: visible;

	.wrapper, .image-overlapFullWrapper {
		position: absolute;
		left: 50%;
	}

	&.image--tall {
		margin-left: auto;
		margin-right: auto;

		@include query_min($flexboxgrid-max-width) {
			margin-left: auto;
			margin-right: auto;
		}
	}
}