//---------- mobile menu ----------
.mobileMenu {
	position: fixed;
	z-index: 1000;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	min-height: 100%;
	overflow-y: scroll;
	display: none;
	@include opacity(0);
	transition: opacity $transitionTime;

	&.mobileMenu-block {
		display: block;
	}

	&.mobileMenu-in {
		@include opacity(1);
	}
}

.mobileMenu-inner {
	@extend %judsonBorderedBackground;
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	min-height: min-content;
}

.mobileMenu-header {
	@extend .header;
}

//---------- mobile menu toggle ----------
@keyframes menu-intro {
	from {
		transform: scale(0);
		opacity: 0;
	}
	80% {
		transform: scale(1.25);
	}
	to {
		transform: scale(1);
		opacity: 1;
	}
}

@keyframes menu-intro-lines {
	from {
		opacity: 0;
		width: 0;
	}
	40% {
		opacity: 0;
		width: 0;
	}
	to {
		opacity: 1;
		width: 28px;
	}
}

@keyframes menu-intro-lines-sub {
	from {
		opacity: 0;
		width: 0;
	}
	40% {
		opacity: 0;
		width: 0;
	}
	to {
		opacity: 1;
		width: 14px;
	}
}

@keyframes menu-intro-lines-pulse {
	0%, 100% {
		background-color: $c-thunder;
	}
	50% {
		background-color: $c-flamingo;
	}
}

.mobileMenu-toggle {
	position: fixed;
	background-color: #fff;
	cursor: pointer;
	width: $fs-menuToggleSize;
	height: $fs-menuToggleSize;
	border-radius: 50%;
	margin-top: 0;
	top: 15px;
	right: 15px;
	z-index: 1001;
	pointer-events: none;
	transition: transform, opacity 1s;
	opacity: 0;

	.js & {
		opacity: 1;
		pointer-events: auto;
	}

	.homepage.js & {
		animation: $transitionTime menu-intro;
	}

	.homepage & {
		@include query_max($bp-desktop-px - 1px) {
			display: none;
		}
	}

	.homepage--clicked & {
		transition-duration: $transitionTimeFast;
		opacity: 0;
		transform: scale(0);
		pointer-events: none;
	}

	@include query_min($bp-mid) {
		top: 46px;
		right: 46px;
	}

	span, span:before, span:after {
		cursor: pointer;
		height: 2px;
		width: 28px;
		position: absolute;
		display: block;
		content: '';
		transition: all $transitionTimeFast ease-in-out;
		background-color: $c-thunder;
		opacity: 0;

		.js & {
			opacity: 1;
		}
	}

	span, span:before {
		.homepage.js & {
			animation-name: menu-intro-lines, menu-intro-lines-pulse;
			animation-duration: $transitionTime*2, 2s;
			animation-timing-function: ease-in-out, linear;
			animation-iteration-count: 1, infinite;
			animation-delay: 0s, $transitionTime*2;
		}
	}

	span:after {
		.homepage.js & {
			animation-name: menu-intro-lines-sub, menu-intro-lines-pulse;
			animation-duration: $transitionTime*2, 2s;
			animation-timing-function: ease-in-out, linear;
			animation-iteration-count: 1, infinite;
			animation-delay: 0s, $transitionTime*2;
		}
	}

	span {
		right: 12px;

		&:before {
			top: -9px;
			right: 0;
		}

		&:after {
			bottom: -9px;
			width: 14px;
			right: 0;
		}

		.mobileMenuOpen & {
			&:after {
				width: 28px;
			}
		}
	}

	span span {
		display: none;
	}
}