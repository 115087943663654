//foreground colors
$arr-foregroundColors: () !default;

//font sizes for various text
$fs-textTinyHeading: 12px !default;
$fs-textLabelHeading: 15px !default;
$fs-textCalloutHeading: 17px !default;
$fs-textCalloutHeadingRem: 1.08rem !default; //override for rems on OS X
$fs-textAncillary: 15px !default;

//headings
$fs-h1: 36px !default;
$fs-h1Small: 30px !default;
$lh-h1: 1.2 !default;
$fs-h2: 32px !default;
$fs-h2Small: 26px !default;
$lh-h2: 1.1 !default;
$fs-h3: 24px !default;
$lh-h3: 1.2 !default;
$fs-h4: 23px !default;
$lh-h4: 1.35 !default;
$fs-h5: 20px !default;
$fs-h6: 18px !default;

body {
	@include fontsize($fs-default);
	font-family: $ff-default;
}

blockquote {
	border-left: 8px solid $c-text;
	padding: $paddingDefault*.5 $paddingDefault*1.5;
}

a {
	color: currentColor;
	text-decoration: none;
}

em {
	font-style: italic;
}

strong {
	font-weight: bold;
}

h1, .fauxH1,
h2, .fauxH2,
h3, .fauxH3,
h4, .fauxH4,
h5, .fauxH5,
h6, .fauxH6 {
	font-family: $ff-tertiary;
	font-weight: normal;
}

h1, .fauxH1 {
	@include fontsize($fs-h1Small);
	@include query_min($bp-sm) {
		@include fontsize($fs-h1);
	}
	line-height: $lh-h1;
}
h2, .fauxH2 {
	@include fontsize($fs-h2Small);
	@include query_min($bp-sm) {
		@include fontsize($fs-h2);
	}
	line-height: $lh-h2;
}
h3, .fauxH3 {
	@include fontsize($fs-h3);
	line-height: $lh-h3;
}
h4, .fauxH4 {
	@include fontsize($fs-h4);
	line-height: $lh-h4;
}
h5, .fauxH5 {
	@include fontsize($fs-h5);
}
h6, .fauxH6 {
	@include fontsize($fs-h6);
}

.text--left {
	text-align: left;
}

.text--right {
	text-align: right;
}

.text--center {
	text-align: center;
}

.text--defaultFamily {
	font-family: $ff-default;
}

.text--secondaryFamily {
	font-family: $ff-secondary;
}

.text--tertiaryFamily {
	font-family: $ff-tertiary;
}

.text--upper {
	text-transform: uppercase;
}

.text--bold {
	font-weight: bold;
}

.text--default {
	@extend %defaultText;
}

.text--specialHeader {
	font-family: $ff-tertiary;
	font-weight: 700
}

.scaling-header {
	@include scaling-fs(10px, 40px);
}

.text-heroHeading {
	font-family: $ff-tertiary;
	color: $c-iron;
	letter-spacing: .05em;
	line-height: 1;
}

.text-largeHeading {
	font-weight: bold;
	@include scaling-fs(30px, 45px);
	line-height: 50/45;
}

.text-bannerHeading {
	@extend .text--specialHeader;
	@include scaling-fs(30px, 73px);
	line-height: 1;
}

.text-bannerSubHeading {
	font-family: $ff-secondary;
	text-transform: uppercase;
	letter-spacing: 1.5px;
	@include scaling-fs(12px, 20px);
	@include opacity(.8);
}

%text-heading {
	font-family: $ff-secondary;
	font-weight: bold;
	text-transform: uppercase;
}

.text-tinyHeading {
	@extend %text-heading;
	@include fontsize($fs-textTinyHeading);
	color: $c-silverChalice;
	letter-spacing: rems(1.4px);
}
.text-tinyLabel {
	@include fontsize($fs-textTinyHeading);
	color: $c-silverChalice;
}
.text-labelHeading {
	@extend %text-heading;
	@include fontsize($fs-textLabelHeading);
	color: $c-silverChalice;
	letter-spacing: rems(1.5px);
}

.text-calloutHeading {
	@extend %text-heading;
	font-size: $fs-textCalloutHeading;
	font-size: $fs-textCalloutHeadingRem;
	letter-spacing: rems(1.6px);
}

.text-bottomBorder {
	&:after {
		font-size: inherit;
		content: '';
		height: 1px;
		width: 23px;
		background-color: $c-flamingo;
		display: block;
		margin-top: $marginDefault*.75;

		@include query_min($bp-mid) {
			margin-top: $marginDefault;
		}
	}
}

.text-bottomBorder--center {
	&:after {
		margin-left: auto;
		margin-right: auto;
	}
}

.text-ancillary {
	@include fontsize($fs-textAncillary);
}

.text-styledLinks {
	&.bg--light {
		a, .fauxA {
			border-bottom: 1px dotted currentColor;
			transition: color $transitionTimeFast, border-bottom-color $transitionTimeFast;

			&:hover {
				color: $c-flamingo !important;
			}
		}
	}
}

@each $name, $color in $arr-foregroundColors {
	.fg-#{$name} {
		color: $color;
	}
}