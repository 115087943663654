$arr-backgroundsDark: () !default;
$arr-backgroundsLight: () !default;

@each $name, $color in $arr-backgroundsDark {
	.bg-#{$name} {
		background-color: $color;
		color: #fff;

		//auto-collapse successive blocks of the same bg color
		& + & {
			padding-top: 0;
		}

		//close a little margin between text blocks following images
		&.block-image:not(.block-padded--bottom-none) + &.block-richText {
			margin-top: $marginDefault * -1;
		}

		//tighten up captions that are following images on mobile
		@include query_max($bp-mid) {
			&.block-image:not(.block-padded--bottom-none) + &.textStyle--insetCaption,
			&.block-image:not(.block-padded--bottom-none) + &.textStyle--caption {
			margin-top: $marginDefault * -2;
				padding-bottom: $paddingDefault*4;
			}
		}

		//close some margin between consecutive text blocks
		&.block-richText:not(.block-padded--bottom-none) + &.block-richText {
			margin-top: $marginDefault * -3;
		}
	}
}

@each $name, $color in $arr-backgroundsLight {
	.bg-#{$name} {
		background-color: $color;
		color: $c-text;

		//auto-collapse successive blocks of the same bg color
		& + & {
			padding-top: 0;
		}

		//close some margin between text blocks following images
		&.block-image:not(.block-padded--bottom-none) + &.block-richText {
			margin-top: $marginDefault * -1;
		}

		&.block-image:not(.block-padded--bottom-none) + &.textStyle--insetCaption,
		&.block-image:not(.block-padded--bottom-none) + &.textStyle--caption {
			margin-top: $marginDefault * -2;
		}


		//close some margin between consecutive text blocks
		&.block-richText:not(.block-padded--bottom-none) + &.block-richText {
			margin-top: $marginDefault * -3;
		}
	}
}

.background--fixedFull {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	z-index: -1;

	@include query_min($bp-desktop) {
		top: 7px;
		right: 7px;
		bottom: 7px;
		left: 7px;
	}
}

.background-image--full {
	@include opacity(.35);
}

.background-video--full {
	background: $c-bodyBg url(/assets/img/noise.png) repeat 0 0;
	display: none;
	@include query_min($bp-desktop) {
		display: block;
	}
}

.background-video {
	position: absolute;
	top: 50%;
	left: 50%;
	width: auto;
	height: auto;
	min-width: 100%;
	min-height: 100%;
	transform: translate(-50%, -50%);
	@include opacity(.12);
}
