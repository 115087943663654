.heroTitle-spacer {
	position: relative;
	z-index: -1;
	@include scaling(48, 188, 'height');
	pointer-events: none;
	@extend %noSelect;
}

.heroTitle-wrapper {
	width: 100%;
	position: absolute;
	left: 0;
	right: 0;
	@include scaling(-30, -144, 'top');
	overflow: hidden;
}

.heroTitle-inner {
	display: inline-block;
	position: relative;
	left: 50%;
}

.heroTitle {
	@extend .text-heroHeading;
	@include scaling-fs(140px, 600px);
	text-transform: lowercase;
	text-align: center;
	margin-left: -50%;
	white-space: nowrap;
	display: inline-block;
}

.heroTitle-holder + .block.bg-transparent {
	padding-top: 0;
}

// a special override for related case studies
// if they are the first block immediately
// following a hero title spacer
.heroTitle-holder + .block-caseStudiesRelated {
	@include scaling(-48, -65, 'margin-top');
}