//cp edit link
$fs-cpEditLink: 16px !default;

.block-cpEditLink {

}

.cpEditLinkInline {
	padding-left: $paddingDefault*.5;
	text-decoration: none;
	color: currentColor !important;
	@include opacity(.25);

	&:hover {
		@include opacity(1);
	}
}