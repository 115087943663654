$fs-buttons: 15px !default;

.button {
	display: inline-block;
	text-align: center;
	cursor: pointer;
	text-decoration: none;
	font-weight: bold;
	font-family: $ff-secondary;
	border: 1px solid black;
	color: black;
	vertical-align: middle;
	padding: $paddingDefault;
	text-transform: uppercase;
	letter-spacing: 1px;
	@include fontsize($fs-buttons);

	@include query_min($bp-mid) {
		padding: $paddingDefault*1.25 $paddingDefault*2.75;
	}

	@at-root a#{&} {
		color: $c-text;
	}

	&:hover, .button-puppeteer:hover & {
		border-color: $c-flamingo;
		background-color: $c-flamingo;
		color: #fff;
	}

	[class^="icon-"], [class*=" icon-"] {
		position: relative;
		top: 1px;
		margin-right: $marginDefault*.25;
	}
}

.button--sm {
	padding: 9px 18px !important;
}

.button--block {
	display: block;
}

.button-holder {
	margin-left: auto;
	margin-right: auto;

	.button {
		margin: $marginDefault*.5 0 0 0;
	}

	.button + .button {
		margin-left: $marginDefault*1.5;
		margin-right: $marginDefault*1.5;

		@include query_min($bp-mid) {
			margin-right: 0;
		}
	}
}

.button-wrapper {
	display: block;
	text-align: center;
}