$fs-fancyList: 15px;

.columns--fancyList {
	display: block;
	@include fontsize($fs-fancyList);
	color: #000;
	padding: 0;
	column-width: 214px;
	column-count: 4;
	@include scaling(120,214, 'column-width', $bp-sm-px, $flexboxgrid-max-width-px);
	@include scaling($gutter-width-px, 86px, 'column-gap', $bp-sm-px, $flexboxgrid-max-width-px);

	li {
		display: block;
		clear: both;
		list-style-type: none;
		padding-top: $paddingDefault;
		-moz-column-break-inside:avoid;
		-webkit-column-break-inside:avoid;
		column-break-inside:avoid;
	}

	li + li {
		margin-top: 0 !important;
	}
}