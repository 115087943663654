$fs-footerLogo: 58px !default;

.footer {
	padding-top: 0;
	padding-bottom: $paddingDefault*2.5;

	h2 {
		@include fontsize(27px);
	}
}

.footer-logoHolder {
	text-align: center;
	margin: $marginDefault*3 auto;
	max-width: 450px;
	position: relative;

	a {
		display: inline-block;
		vertical-align: middle;
	}

	.footer-logo {
		align-self: center;
	}

	.pageLink {
		position: absolute;
		top: 45%;
	}

	.pageLink-prev {
		left: 0;
	}

	.pageLink-next {
		right: 0;
	}
}

.footer-logo {
	@include fontsize($fs-footerLogo);
	color: $c-flamingo;
	text-decoration: none;
}

.contact-links, .contact-address {
	color: $c-gray;
	@include fontsize(17px);
}
.contact-buttons {
	margin-top: $marginDefault*.85;
}
.contact-links {
	margin-top: $marginDefault*.5;

	li {
		margin-top: $marginDefault*.1;
	}

	span {
		font-family: $ff-secondary;
		text-transform: uppercase;
		font-weight: bold;
		font-size: 17px;
		font-size: 1.08rem; //override for rems on OS X
		letter-spacing: 1px;
	}

	a {
		text-decoration: none;
	}

	span, a {
		display: inline-block;
		vertical-align: middle;
	}
}

.contact-address {
	margin-top: $marginDefault*1.25;
	@include query_min($bp-desktop) {
		margin-top: $marginDefault*.7;
	}
}