//---------- clearfix ----------
.clearfix {
	&:before,
	&:after {
		content: "";
		display: table;
	}
	&:after {
		clear: both;
	}

	//zoom: 1; /* For IE 6/7 (trigger hasLayout) */
}

//---------- padding/margins ----------
%lobotomize {
	* + * {
		margin-top: $marginDefault;
	}

	//fixes a firefox bug
	br {
		margin: 0 !important;
	}
}

%lobotomize--half {
	* + * {
		margin-top: $marginDefault*.5;
	}

	//fixes a firefox bug
	br {
		margin: 0 !important;
	}
}

%lobotomize--threeQuarters {
	* + * {
		margin-top: $marginDefault*.75;
	}

	//fixes a firefox bug
	br {
		margin: 0 !important;
	}
}

//---------- text ----------
%hideText {
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
}

%antialiased {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

%defaultText {
	@include fontsize($fs-default);
	line-height: $lh-default;
}

%noSelect {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.text-content {
	@extend %defaultText;
	@extend %lobotomize;
}

//---------- images ----------
.img-stretch {
	width: 100%;
}

.background--cover {
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
}

.background--full {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
}

//---------- hidden ----------
.hidden {
	display: none !important;
	visibility: hidden;
}

.visuallyHidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

.invisible {
	visibility: hidden;
}

//---------- super text ----------
.sup {
	font-size: 70%;
	display: inline-block;
	vertical-align: top;
}

//---------- zoom in cursor ----------
.js {
	.zoom-in, a.zoom-in {
		cursor: pointer;
		cursor: -webkit-zoom-in;
		cursor: -moz-zoom-in;
		cursor: zoom-in;
	}
}

//---------- hackish centering ----------
.table {
	display: table;
}

.table-row {
	display: table-row;
}

.table-cell {
	display: table-cell;
	vertical-align: middle;
}

//---------- loading ----------
.loading {
	min-height: 80px;
	background: url(/assets/svg/ajax-loader-white.svg) no-repeat center center;
}

//---------- widths ----------
.fullWidth {
	width: 100%;
	margin: 0 auto;
}

//---------- vue ----------
[v-cloak] {
	display: none !important;
}

//---------- bordered background ----------
%judsonBorderedBackground {
	background: $c-bodyBg url(/assets/img/noise.png) repeat 0 0;
	&:before, &:after {
		content: "";
		position: fixed;
		background: #fff;
		left: 0;
		right: 0;
		height: 7px;
		display: none;
		z-index: 110;
	}
	&:before {
		top: 0;
	}
	&:after {
		bottom: 0;
	}

	@include query_min($bp-desktop) {
		border-left: 7px solid #fff;
		border-right: 7px solid #fff;

		&:before, &:after {
			display: block;
		}
	}
}