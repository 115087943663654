$fs-listingSquareOverlay: 13px !default;

// ---------- Case Study Listings ----------
%caseStudyWrapper {
	position: relative;
	& + & {
		margin-top: $marginDefault*2;
	}

	a.cpEditLinkInline {
		position: absolute;
		bottom: $marginDefault;
		right: $marginDefault;
		z-index: 2;
		margin-top: 0;
		@include query_max($bp-sm-px - 1px) {
			display: none;
		}
	}
}
.listing-caseStudy-wrapper {
	@extend %caseStudyWrapper;
}

%caseStudyListing {
	display: block;
	position: relative;
	text-decoration: none;
	padding-top: 52.356%;
	z-index: 1;

	@at-root a#{&} {
		color: $c-text;
	}

}
.listing--caseStudy {
	@extend %caseStudyListing;
	@extend .diamondParent--onHover !optional;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(#fff, 0);
		z-index: 2;
		transition: background-color $transitionTime;
	}

	&:hover {
		&:before {
			background-color: rgba(#fff, .5);
		}
	}

	.text-content {
		position: absolute;
		top: 50%;
		right: 6.75%;
		transform: translateY(-50%);
		@include scaling(95, 264, 'width', $bp-sm-px, $bp-md-px);
		width: 50%;
		z-index: 3;
	}

	.listing-cta {
		span {
			display: inline-block;
			@extend .diamondLineRight !optional;
			@extend .diamondLeft !optional;
			padding-left: $paddingDefault;
			left: - $marginDefault;
			padding-right: $paddingDefault;
		}
	}
}

// ---------- Generic Listing Items ----------
.listing-number {
	font-family: $ff-secondary;
	@extend .text-labelHeading;
	@extend .text-bottomBorder;
}

.listing-heading {
	font-family: $ff-tertiary;
	font-weight: bold;
	@include scaling-fs(20px, 45px);
	@include fontsize($fs-default);
	line-height: 50/45;
}

.listing-services {
	@extend .text-ancillary;
	span {
		@extend .text-labelHeading
	}
}

.listing-cta {
	@extend .text-calloutHeading;
	margin-top: $marginDefault*3;
}

.listing-reverseLogo {
	position: absolute;
	left: 0;
	top: 0;
	background-color: #000;
	@include scaling(100px, 233px, 'width', $bp-sm-px, $bp-desktop-px);
	@include scaling(41px, 96px, 'height', $bp-sm-px, $bp-desktop-px);
	z-index: 1;
}

.listing-reverseLogo-inner {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-size: contain;
	background-repeat: no-repeat;
	background-origin: content-box;
	background-position: center center;
	padding: 5% 10%;
}

// ---------- Square Listings (Related Case Studies ----------
.listing--square {
	position: relative;

	img {
		display: block;
	}

	a.cpEditLinkInline {
		position: absolute;
		top: $marginDefault;
		right: $marginDefault;
		z-index: 2;
		margin-top: 0;
		@include query_max($bp-sm-px - 1px) {
			display: none;
		}
	}
}

.listing--square-link {
	display: block;



	/*&:hover {
		.listing--square-overlay {
			background-color: $c-flamingo;
		}
	}*/
}

.listing--square-overlay {
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 2;
	width: 100%;
	background-color: black;
	color: #fff;
	text-transform: uppercase;
	font-family: $ff-secondary;
	@include fontsize($fs-listingSquareOverlay);
	font-weight: bold;
	padding: $paddingDefault*1.1;
}

// ---------- Case Study Presentation Listings ----------
.listing-presentation-wrapper {
	@extend %caseStudyWrapper;
}

.listing--presentation {
	@extend %caseStudyListing;
	@extend .diamondParent--onHover !optional;

	.text-content {
		position: absolute;
		top: 50%;
		left: 0;
		width: 100%;
		text-align: center;
		color: #fff;
		transform: translateY(-50%);
		z-index: 2;

		h2 {
			max-width: 400px;
			margin: 0 auto;
			padding: 0 $paddingDefault;
			position: relative;
			@extend .diamondLineBoth !optional;
		}
	}

	&:before {
		content: '';
		background-color: rgba($c-flamingo, .5);
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		transition: background-color $transitionTime;
	}

	&:hover:before {
		background-color: rgba($c-flamingo, .25);
	}
}