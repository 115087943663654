//Remove text-shadow in selection highlight: https://twitter.com/miketaylr/status/12228805301
//(These selection rule sets have to be separate.)
::-moz-selection {
	color: white;
	background: $c-selection;
	text-shadow: none;
}

::selection {
	color: white;
	background: $c-selection;
	text-shadow: none;
}

//Remove the gap between audio, canvas, iframes, images, videos and the bottom of their containers
//https://github.com/h5bp/html5-boilerplate/issues/440
audio,
canvas,
iframe,
img,
svg,
video {
	vertical-align: middle;
}

//Remove default fieldset styles.
fieldset {
	border: 0;
	margin: 0;
	padding: 0;
}

//Allow only vertical resizing of textareas.
textarea {
	resize: vertical;
}

//---------- Base styles ----------
*,
*:after,
*:before {
	box-sizing: border-box;
}

* {
	outline: none;
}

html {
	@extend %antialiased;
	text-rendering: optimizeLegibility;
	width: 100%;
	height: 100%;
	overflow: initial;
}

body {
	position: relative;
	margin: 0 auto;
	padding: 0;
	color: $c-text;
	font-family: $ff-default;
	@include fontsize($fs-default);
	line-height: $lh-default;
	overflow-x: hidden;
	overflow-y: scroll;
	background: $c-bodyBg;
	width: 100%;
	min-height: 100vh;
}

a {
	color: currentColor;
}

i {
	font-style: normal;
}

button {
	border: none;
	background: transparent;
}

img {
	max-width: 100%;
	vertical-align: middle;
	height: auto;
}

figure {
	margin: 0;
}

figcaption {

}