html {
	&.mobileMenuOpen {
		overflow: hidden;
	}
}

body {
	.mobileMenuOpen & {
		overflow-y: hidden;
	}

	@extend %judsonBorderedBackground;
	display: flex;
	flex-direction: row;
}

.holder {
	display: flex;
	flex-direction: column;
	width: 100%;
	min-height: 100vh;
}

.header {
	z-index: 4;
	width: 100%;
	flex: none;
	overflow: visible !important;
}

.main {
	@extend .clearfix;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	flex: 1 0 auto;
	z-index: 3;
	position: relative;
	width: 100%;

	> * {
		width: 100%;
	}

	&:after {
		content: '\00a0';
		display: block;
		height: 0;
		visibility: hidden;
	}
}

.block-cpEditLink {
	margin-top: auto;

	.full & {
		margin-top: 0;
	}
}

.footer {
	flex: none;
	margin-top: auto;
	position: relative;
	z-index: 2;
}

.flexAutoHeightColumn {
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
	flex-wrap: nowrap;
	flex-flow: nowrap column;
}

.position--relative {
	position: relative;
}