.filters {
	border: 1px solid black;
	padding: $paddingDefault;

	label, button {
		@extend .text-tinyHeading;
	}

	button {
		color: black;

		&:hover, &.selected {
			color: $c-flamingo;
		}
	}

	select {
		margin-left: $marginDefault;
		font-family: $ff-secondary;
	}
}

.block-filter-results {

	&.loading {
		min-height: 200px;
	}

	.block:first-of-type {
		padding-top: 0;
	}
}