.sectionMarker-marker {
	@extend .text-heroHeading;
	@include scaling-fs(100px, 600px);
	text-align: right;
	position: absolute;
	//@include scaling(-30, -50, 'bottom');
	bottom: -0.575em;
	@include scaling($gutter-width-double-px, -200, 'right', $flexboxgrid-max-width-px, 1600px);
	overflow: hidden;
}

.sectionMarker-spacer {
	position: relative;
	z-index: -1;
	@include scaling(30, 240, 'height');
	pointer-events: none;
	@extend %noSelect;
}

.sectionMarker-content {
	padding: $paddingDefault*2;

	@include query_min($bp-desktop) {
		padding: $paddingDefault*4 $paddingDefault*6;
	}
}