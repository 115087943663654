//---------- diamond parent element ----------
.diamondParent--onHover:hover, .diamondParent {
	.diamondLineBoth,
	.diamondLineLeft,
	.diamondLineBoth--black,
	.diamondLineLeft--black {
		&:before {
			margin-right: 0 !important;
		}
	}

	.diamondLineBoth,
	.diamondLineRight,
	.diamondLineBoth--black,
	.diamondLineRight--black {
		&:after {
			margin-left: 0 !important;
		}
	}

	.diamondBoth,
	.diamondLeft {
		&:before {
			transform: scale(1);
		}
	}

	.diamondBoth,
	.diamondRight {
		&:after {
			transform: scale(1);
		}
	}
}

//---------- diamond lines ----------
%diamondLine {
	content: '';
	position: absolute;
	width: 2000px;
	height: 9px;
	top: 50%;
	background-color: transparent;
	background-size: auto;
	background-repeat: no-repeat;
	transform: translateY(-50%);
}

.diamondLineBoth,
.diamondLineLeft,
.diamondLineBoth--black,
.diamondLineLeft--black {
	position: relative;

	&:before {
		@extend %diamondLine;
		right: 100%;
		background-image: url(/assets/svg/diamond-line-left-flamingo.svg);
		background-position: right center;
		transition: margin-right $transitionTime;
		margin-right: 60vw;
	}
}

.diamondLineBoth--black,
.diamondLineLeft--black {
	&:before {
		background-image: url(/assets/svg/diamond-line-left-black.svg);
	}
}

.diamondLineBoth,
.diamondLineRight,
.diamondLineBoth--black,
.diamondLineRight--black {
	position: relative;

	&:after {
		@extend %diamondLine;
		left: 100%;
		background-image: url(/assets/svg/diamond-line-right-flamingo.svg);
		background-position: left center;
		transition: margin-left $transitionTime;
		margin-left: 60vw;
	}
}

.diamondLineBoth--black,
.diamondLineRight--black {
	&:after {
		background-image: url(/assets/svg/diamond-line-right-black.svg);
	}
}

//---------- diamonds ----------
%diamond {
	content: '';
	position: absolute;
	width: 9px;
	height: 9px;
	top: 50%;
	top: calc(50% - 4.5px);
	transform: scale(0);
	background: transparent url(/assets/svg/diamond-flamingo.svg) no-repeat center center;
	background-size: auto;
	transition: transform $transitionTime;
	transition-delay: 0s;
}

.diamondBoth,
.diamondLeft {
	position: relative;

	&:before {
		@extend %diamond;
		right: 100%;
	}
}

.diamondBoth,
.diamondRight {
	position: relative;

	&:after {
		@extend %diamond;
		left: 100%;
	}
}