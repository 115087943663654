$iconFontPath: "/assets/fonts" !default;
$iconHash: '9n9n4b';
$ff-icons: 'icomoon' !default;

@font-face {
	font-family: $ff-icons;
	src:  url('#{$iconFontPath}/#{$ff-icons}.eot?#{$iconHash}');
	src:  url('#{$iconFontPath}/#{$ff-icons}.eot?#{$iconHash}#iefix') format('embedded-opentype'),
	url('#{$iconFontPath}/#{$ff-icons}.ttf?#{$iconHash}') format('truetype'),
	url('#{$iconFontPath}/#{$ff-icons}.woff?#{$iconHash}') format('woff'),
	url('#{$iconFontPath}/#{$ff-icons}.svg?#{$iconHash}##{$ff-icons}') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"], [class*=" icon-"], .icon, %icon-font {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: $ff-icons !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Enable Ligatures ================ */
	letter-spacing: 0;
	-webkit-font-feature-settings: "liga";
	-moz-font-feature-settings: "liga=1";
	-moz-font-feature-settings: "liga";
	-ms-font-feature-settings: "liga" 1;
	font-feature-settings: "liga";
	-webkit-font-variant-ligatures: discretionary-ligatures;
	font-variant-ligatures: discretionary-ligatures;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	&:before {
		outline: 1px solid transparent;
	}
}

//---- Icomoon Classes - Do Not Remove ----
.icon-edit {
  &:before {
    content: $icon-edit;
  }
}
.icon-phone {
  &:before {
    content: $icon-phone;
  }
}
.icon-email {
  &:before {
    content: $icon-email;
  }
}
.icon-twitter {
  &:before {
    content: $icon-twitter;
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook;
  }
}
.icon-pinterest {
  &:before {
    content: $icon-pinterest;
  }
}
.icon-long-arrow-right {
  &:before {
    content: $icon-long-arrow-right;
  }
}
.icon-long-arrow-left {
  &:before {
    content: $icon-long-arrow-left;
  }
}
.icon-circle {
  &:before {
    content: $icon-circle;
  }
}
.icon-play {
  &:before {
    content: $icon-play;
  }
}
.icon-crossbones {
  &:before {
    content: $icon-crossbones;
  }
}
.icon-diamond-filled {
  &:before {
    content: $icon-diamond-filled;
  }
}
.icon-diamond {
  &:before {
    content: $icon-diamond;
  }
}
//---- End Icomoon Classes - Do Not Remove ----