$fs-newIcons: 16px !default;

//---------- social ----------
.new-social {
	@include fontsize($fs-newIcons);
	margin-top: $marginDefault*.5;

	@at-root ul#{&} {
		padding: 0;
	}

	li {
		display: inline-block;
		list-style-type: none;
	}

	li + li {
		margin-top: .5em;
		margin-left: $marginDefault;
	}

	a {
		display: inline-block;
		padding: 5px;
		text-decoration: none;
		color: $c-silverChalice;
		transition: color $transitionTimeFast, transform $transitionTimeFast;
		position: relative;

		&:hover {
			color: $c-flamingo;
			transform: scale(1.25);
			z-index: 2;
		}
	}
}

//---------- posts ----------
.new-text {
	padding: 0 $paddingDefault;

	@include query_min($bp-lg) {
		padding: 0;
	}
}

//---------- featured ----------
.new-featuredImageSlides {
	img {
		margin-left: auto;
		margin-right: auto;
	}
}

.new-featuredInfoSlides {
	margin-top: $marginDefault;
}