main {
	ul, ol {
		padding-left: $paddingDefault;
		text-align: left;
	}

	li + li {
		margin-top: $marginDefault*.25;
	}

	ul li {
		list-style-type: disc;
	}

	ol li {
		list-style-type: decimal;
	}
}